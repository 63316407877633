import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
import Seo from '../utils/seo';
import { Layout } from '../layout';
import { device } from '../utils/devices';

import {
  Text,
  TypewriterText,
  BulletPointCard,
  SpotifyList,
  AnimationFadeWrapper,
} from '../components';

const PageHeader = styled.div`
  position: sticky;
  inset: 56px auto auto;
  scroll-margin-top: 64px;
  z-index: 1;

  height: calc(100vh - 56px - 2.5rem);
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding-block-start: calc((95vh - 56px) / 2 - 120px);
`;

const PageHeaderTitle = styled.h2`
  font-size: 2.5rem;
  line-height: 3.75rem;
  font-weight: 400;
  letter-spacing: -0.02em;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-block-start: 24px;
  @media ${device.tablet} {
    font-size: 3rem;
    line-height: 4.5rem;
    padding-block-start: 32px;
  }
  @media ${device.laptop} {
    font-size: 3.5rem;
    line-height: 5.25rem;
    padding-block-start: 40px;
  }
`;

const ContentContainer = styled.article`
  position: relative;
  z-index: 2;  
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 100%;
  margin: auto;
  gap: 40px 0;

  background-color: var(--color-background);

  transition-property: flex-grow;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;
  

  
  @media ${device.tablet} {
  }
  @media ${device.tabletLarge} {
  }
  @media ${device.laptop} {
    max-width: 1024px;
  } 
  @media ${device.desktop} {
    max-width: 1440px;
  } 
  @media ${device.fourk} {
  } 
}

  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
  @media ${device.fourk} {
  }
`;

const BoxWithPicture = styled.article`
  align-self: center;
  border-radius: 20px;
  padding-block: 32px;
  padding-inline: 24px;
  background-color: var(--color-surface);

  @media ${device.tablet} {
    padding-block: 40px;
    padding-inline: 40px;
  }
  @media ${device.laptop} {
  }
  @media ${device.desktop} {
    padding-block: 56px;
    padding-inline: 56px;
  }
  @media ${device.fourk} {
    padding-block: 64px;
    padding-inline: 64px;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  gap: 40px;
  align-items: start;

  transition-property: flex-grow, flex-shrink;
  transition-duration: 0.4s;
  transition-timing-function: ease-out;

  flex-direction: column;
  @media ${device.laptop} {
    gap: 40px;
    align-items: center;
    flex-direction: row;
  }
  @media ${device.desktop} {
    gap: 56px;
  }
  @media ${device.fourk} {
    gap: 64px;
  }
`;

const TextSection = styled.div`
  flex: 1;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-block-end: 40px;
  @media ${device.laptop} {
    gap: 24px;
    padding-block-end: 64px;
  }
  @media ${device.desktop} {
    gap: 32px;
    padding-block-end: 80px;
  }
`;

const ImageWrapper = styled(GatsbyImage)`
transition-property: height, width, max-width, max-height;
transition-duration: 0.2s;
transition-timing-function: ease-out;
border-radius 20px;
flex: 1 0;
margin-block: 0 -80px;
align-self: center;

//safari bugfix
-webkit-mask-image: -webkit-radial-gradient(white, black);

@media ${device.tablet} {
  max-width: 520px;
}
@media ${device.laptop} {
  margin-block: -104px;
}
@media ${device.desktop} {
  max-width: 520px;
}
@media ${device.fourk} {
}
`;

const SectionContainer = styled.section`
  padding-block: 80px 0;
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
  @media ${device.desktop} {
    padding-block: 160px 0;
  }
  @media ${device.fourk} {
    padding-block: 200px 0;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 0 64px;
  gap: 8px;
  @media ${device.laptop} {
    gap: 12px;
    padding-block: 0 96px;
  }
  @media ${device.fourk} {
    padding-block: 0 104px;
  }
`;

const BulletPointWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  transition-property: height, width, max-width, max-height,
  transition-duration: 0.2s;
  transition-timing-function: ease-out;

  @media ${device.laptop} {
    gap: 64px 72px;
  }
  @media ${device.fourk} {
    gap: 80px 96px;
  }
  
  > * {
    flex: 1 1 100%;
    @media ${device.laptop} {
      flex: 1 1 calc(50% - 72px);
    }
    @media ${device.fourk} {
      flex: 1 1 calc(50% - 96px);
    }
  }
`;

const SectionContent = styled.div`
display: flex;
flex-direction: column;
gap: 40px;
transition-property: height, width, max-width, max-height,
transition-duration: 0.2s;
transition-timing-function: ease-out;

@media ${device.laptop} {
  gap: 64px;
}
@media ${device.fourk} {
  gap: 80px;
}

`;

const SplitTextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 20px;

  @media ${device.laptop} {
    flex-wrap: nowrap;
    gap: 64px 72px;
  }
  @media ${device.fourk} {
    gap: 80px 96px;
  }

  > :first-child {
    width: 100%;

    @media ${device.laptop} {
      width: 16%;
    }
    @media ${device.fourk} {
    }
  }
`;

const BulletList = styled.ul`
  display: flex;
  flex-flow: column wrap;
  gap: 24px;
  list-style-position: inside;
  list-style: circle;

  @media ${device.laptop} {
    list-style-position: outside;
  }

  > * {
  }
`;

interface AboutProps {
  data: any;
  aboutImage: any;
}

const AboutPage: React.FC<AboutProps> = ({ data }) => {
  return (
    <>
      <Layout withHeader withFooter>
        <AnimationFadeWrapper duration={0.3}>
          <PageHeader>
            <PageHeaderTitle>
              <TypewriterText
                firstWords={['Hello there.', "Let's get acquainted."]}
              />
            </PageHeaderTitle>
          </PageHeader>
          <ContentContainer>
            <BoxWithPicture id="intro">
              <BoxWrapper>
                <TextSection>
                  <TitleWrapper>
                    <Text heading4 as="h2">
                      I’m Jonathan.
                    </Text>
                    <Text heading5 medium as="h3">
                      A designer and consultant based in Oslo, Norway.
                    </Text>
                  </TitleWrapper>
                  <Text body1 as="p">
                    Thanks for visiting my site.
                    <br />
                    <br />
                    If you want to know more about me, you have come to the
                    right place.
                    <br></br>
                    <br></br>
                    If you would rather skip the reading, and grab a cup of
                    coffee instead, you can reach me on{' '}
                    <a
                      href="https://twitter.com/jonathan_esbjug"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                    ,{' '}
                    <a
                      href="https://www.linkedin.com/in/jonathanesbjug/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </a>
                    , through slack on{' '}
                    <a
                      href="https://uxnorge.no/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      UX Norge
                    </a>
                    , or add me on Discord (Esbjug#5852). We'll figure out the
                    rest.
                    <br></br>
                    <br></br>Continue reading, and I'll paint you a picture of
                    who I am.
                  </Text>
                </TextSection>

                <ImageWrapper
                  loading="eager"
                  image={getImage(data.aboutImage)}
                  alt={
                    'A photo of me, Jonathan, sitting at a table with a computer in front of me smiling. Behind me is a bookshelf and a lamp.'
                  }
                />
              </BoxWrapper>
            </BoxWithPicture>
            <SectionContainer>
              <SectionHeader>
                <Text heading4 light as="h2">
                  Tell me the facts and I’ll learn.<br></br>Tell me the truth
                  and I’ll believe.<br></br>But tell me a story and it will live
                  in my heart forever.
                </Text>
              </SectionHeader>
              <SectionContent>
                <Text body1 as="p">
                  The first thing you should know about me is that I live to
                  experience stories.
                  <br></br>
                  <br></br>
                  Stories of imagined worlds. Stories of our own. Stories about
                  the past. Stories about the future.
                  <br></br>
                  <br></br>I enjoy stories because they challenge my
                  understanding of the world. They are tools to contemplate on
                  what is different.
                  <br></br>
                  <br></br>
                  To me, a story is like traveling to a whole new place, where
                  the destinations are boundless. I could go anywhere, at any
                  time. Fortunately, that’s always where I want to go.
                  <br></br>
                  <br></br>
                  Experiencing a good story is not a passive experience, it’s an
                  attentive activity. Being enveloped in a story helps me
                  actively think and feel through the eyes of others. It helps
                  me train my imagination, and it extends and recontextualises
                  my own preconceptions about how things are supposed to be.
                  <br></br>
                  <br></br>
                  Most of all, they help reframe and pose questions about our
                  own reality. What if this situation was different? What if
                  this thing was real? How would that affect the world around
                  us? What would people do differently?
                  <br></br>
                  <br></br>- What if? -<br></br>
                  <br></br>
                  After experiencing thousands of stories, I believe this
                  questions is the reason why I became a designer.
                  <br></br>
                  <br></br>
                  As a designer I get to ask myself “what if”-questions every
                  day. I get to use my imagination to challenge reality and the
                  status quo. I get to drive us toward something new. Something
                  better.
                </Text>
              </SectionContent>
            </SectionContainer>
            <SectionContainer>
              <SectionHeader>
                <Text heading4 as="h2">
                  The surface
                </Text>
                <Text body1 as="p">
                  I'm also a regular human being. Here's what I'm up to when I'm
                  not working.
                </Text>
              </SectionHeader>
              <BulletPointWrapper>
                <BulletPointCard
                  title="Playing really good video games"
                  icon="controller"
                >
                  I have ~26 years of experience with game overs, and just beat
                  my 1050th game.
                </BulletPointCard>
                <BulletPointCard
                  title="Reading and collecting novels"
                  icon="books"
                >
                  Give me a good book or graphic novel and I'm set.
                </BulletPointCard>
                <BulletPointCard title="Lifting heavy stuff" icon="dumbell">
                  Disciplined enough to keep a tight regimen, but relaxed enough
                  to enjoy rest days to their fullest.
                </BulletPointCard>
                <BulletPointCard title="Frontend development" icon="code">
                  I build apps by thinking and typing on a keyboard.
                </BulletPointCard>
                <BulletPointCard
                  title="Cooking and baking delicious food"
                  icon="cooking"
                >
                  I enjoy making food. I love eating it even more.
                </BulletPointCard>
                <BulletPointCard title="Creating digital art" icon="brush">
                  I like to use technology to create visual imagery.
                </BulletPointCard>
                <BulletPointCard title="Chores" icon="time">
                  Let's be honest. Life is mostly this.
                </BulletPointCard>
                <BulletPointCard title="Sleeping" icon="sleep">
                  Nothing beats waking up well rested.
                </BulletPointCard>
                <BulletPointCard title="Writing" icon="writing">
                  I put words into sentences to create meaning. I don't always
                  succeed, but sometimes I do.
                </BulletPointCard>
                <BulletPointCard title="Creating content" icon="entertainment">
                  I make videos for people to watch on the internet. Around 5,5
                  million have spent 476 600 hours watching my stuff so far.
                </BulletPointCard>
              </BulletPointWrapper>
            </SectionContainer>
            <SectionContainer>
              <SectionHeader>
                <Text heading4 as="h2">
                  My path
                </Text>
                <Text body1 as="p">
                  The next thing you should know about me is that I never
                  intended to become a designer.
                </Text>
              </SectionHeader>
              <SectionContent>
                <Text body1 as="p">
                  Growing up, I wanted to be three things. A lawyer, an
                  engineer, and a psychologist.
                  <br></br>
                  <br></br>
                  As a kid I liked building stuff. I built forts in the snow,
                  sandcastles on the beach, and spent most days with legos and
                  video games. I was a nerdy kid. Math was my favorite subject
                  in school. I was on track to become the ideal engineer.
                  <br></br>
                  <br></br>
                  When I got to high school that changed.
                  <br></br>
                  <br></br>I was doing STEM-subjects, and I was steadily losing
                  interest. Isn’t it ironic how subjects like math, chemistry,
                  and physics, while trying to explain the phenomena of the
                  universe, end up feeling soulless?
                  <br></br>
                  <br></br>I wanted to work with something that was meaningful
                  for humans, and my priorities changed. I ditched STEM, and
                  started studying law at university the age of 19. What’s more
                  meaningful than defending innocent people?
                  <br></br>
                  <br></br>I quickly learned that the legal system wasn’t all
                  about protecting people. It was mostly about how to interpret
                  an established truth. A truth that didn’t deal as much in
                  right or wrong, but in correctness and precedents.
                  <br></br>
                  <br></br>I was cynical. The law appeared to me as archaic
                  decisions by past politicians about how to maintain the status
                  quo of society. Incentivise order. Who is guilty? Who should
                  be punished?
                  <br></br>
                  <br></br>
                  It didn’t care much about actually solving the problem. There
                  had to be a better way.
                  <br></br>
                  <br></br>
                  So, after two years studying law. I pursued a degree in
                  pshychology. I sought to learn more about humans, and help
                  people more directly. Actually making an impact.
                  <br></br>
                  <br></br>
                  At least so I thought.
                  <br></br>
                  <br></br>
                  Through my studies I quickly learned that we were training to
                  treat symptoms, not causes. I found that many of the mental
                  illnesses we face in society were caused by societal decisions
                  and issues much larger and systemic.
                  <br></br>
                  <br></br>I wanted to focus in on the topics that dealt with
                  these issues instead of treating the symptoms. I studied
                  cognitive-, behavioural-, work- and organizational psychology.
                  How people work together in societies and groups. Phenomena
                  like stress, anxiety, perception, decision making, loss
                  aversion, teamwork, and leadership.
                  <br></br>
                  <br></br>
                  Turns out humans are pretty awful at making decisions. As
                  groups we’re even worse.
                  <br></br>
                  <br></br>
                  Many decisions we’ve made in society are based on greed, poor
                  evidence and terrible opinions. It has led us down paths that
                  are hard to return from. Not only are we limiting ourselves as
                  a species, we are destroying our world at the same time. I
                  wouldn’t be able to have much impact through treating the
                  symptoms.
                  <br></br>
                  <br></br>
                  Luckily, I was a naïve young student, and I still wanted to
                  change the world.
                  <br></br>
                  <br></br>I was also a poor student. So I got my diploma and I
                  signed up to study computer science.
                  <br></br>
                  <br></br>
                  That’s when I discovered the world of design, and I knew I was
                  on the right track. Through design I could help create a
                  better future.
                  <br></br>
                  <br></br>
                  In hindsight, I feel like design encompasses all I wanted to
                  do growing up. I wanted to shape society into an egalitarian
                  place. I wanted to build things that could support us into the
                  future. I wanted to understand human beings. To help people
                  live their best lives.
                  <br></br>
                  <br></br>
                  And as any good designer does, I keep this in my mind at all
                  times. I hold it firmly.
                  <br></br>
                  <br></br>I know what I want to see happen through my design.
                  <br></br>
                  <br></br>
                  Design is, after all, making intentions into reality.
                </Text>
              </SectionContent>
            </SectionContainer>
            <SectionContainer>
              <SectionHeader>
                <Text heading4 as="h2">
                  Intermezzo
                </Text>
                <Text body1 as="p">
                  Here are 10 tunes I've listened to this month. Thanks Spotify.
                </Text>
              </SectionHeader>
              <SpotifyList />
            </SectionContainer>
            {/* <SectionContainer>
              <SectionHeader>
              <Text heading4 as="h2">
              My core values.
              </Text>
              <Text body1 as="p">
              Ready to go below the surface? I live by a set of values.
              Let's break them down.
              </Text>
              </SectionHeader>
            </SectionContainer> */}
            <SectionContainer>
              <SectionHeader>
                <Text heading4 as="h2">
                  My purpose and philosophy
                </Text>
                <Text body1 as="p">
                  The final thing you should know about me is my reasoning for
                  spending my life on this, beyond just making a living.
                  <br></br>
                  <br></br>
                  Let me tell you what drives me. My conviction.
                </Text>
              </SectionHeader>
              <SectionContent>
                <SplitTextWrapper>
                  <Text heading5 medium as="h3">
                    Why
                  </Text>
                  <Text body1 as="p">
                    Before I die I want to see society flourish, through
                    meaningful, empathic, effective, ethical, egalitarian and
                    sustainable acts of progress.
                    <br></br>
                    <br></br>I believe that good design is the key to our
                    survival as a species. And I’m not joking.
                    <br></br>
                    <br></br>
                    We have seen the destruction that evil, malign, or stupid
                    design has wrought on us. We have made guns to kill each
                    other. We built societies with awful wealth distributions,
                    large inequalities, and suffering. We constructed engines of
                    mass consumption that’s polluting the world around us.
                    <br></br>
                    <br></br>
                    Leaving things up to chance (and terrible design) is not an
                    option any more. We need people with good intent and actions
                    to shape the world around us in a meaningful way. We need
                    good design done well.
                  </Text>
                </SplitTextWrapper>
                <SplitTextWrapper>
                  <Text heading5 medium as="h3">
                    How
                  </Text>
                  <Text body1 as="p">
                    I believe that my best shot at making this happen is through
                    empowering people I meet with the knowledge, principles and
                    acts of good design.
                    <br></br>
                    <br></br>
                    Tenets, like acting on evidence and understanding. Embody
                    empathy through every action. Creation through discourse and
                    cooperation. Embracing diverse perspective and ideas. Limit
                    premature convergence. Champion beauty and elegance. Work
                    for the good of everyone, not the few.
                    <br></br>
                    <br></br>
                    As an Individual I can only do so much, but I believe that
                    limiting myself is setting myself up for failure. I want to
                    spread the knowledge and inspire others, through words, acts
                    and examples, even if it sounds folly or audacious.
                    <br></br>
                    <br></br>I want to see changes in laws, communities,
                    products, narratives and languages. To see the influence of
                    good design principles throughout society.
                    <br></br>
                    <br></br>
                    We are living in a volatile world. Time will tell of what my
                    impact will be.
                  </Text>
                </SplitTextWrapper>
              </SectionContent>
            </SectionContainer>
            <SectionContainer>
              <SectionHeader>
                <Text heading4 as="h2">
                  Fit
                </Text>
                <Text body1 as="p">
                  Now you know who I am. How I got here. What my purpose is.
                  <br></br>
                  <br></br>
                  Now you get to decide if we should work together, or if you
                  want to have a chat.
                </Text>
              </SectionHeader>
              <SectionContent>
                <SplitTextWrapper>
                  <Text heading5 medium as="h3">
                    We should work together if...
                  </Text>
                  <BulletList>
                    <Text body1 as="p">
                      <li>
                        You have an important design problem, and want to make
                        meaningful change for people and society.
                      </li>
                      <li>
                        You know the value of good design. You want to create
                        impactful results through outcomes.
                      </li>
                      <li>
                        You want a competent design partner. Someone who
                        supports and challenges you to create better results.
                      </li>
                      <li>
                        You feel like you need help with design and
                        design-culture. You want to improve how you work with
                        design in your organization and need a place to start.
                      </li>
                      <li>
                        You want someone to lead design in a product development
                        team.
                      </li>
                      <li>
                        You got this far down on the page without closing your
                        browser tab. Nice.
                      </li>
                      <br></br>
                      <br></br>
                    </Text>
                    <Text body1 as="p">
                      If some of those sounds good to you, here’s what you will
                      get by working with me. And what you won’t get.
                    </Text>
                  </BulletList>
                </SplitTextWrapper>

                <SplitTextWrapper>
                  <Text heading5 medium as="h3">
                    You will get
                  </Text>
                  <BulletList>
                    <Text body1 as="p">
                      <li>
                        Help with holistic problem solving through ethical
                        product design.
                      </li>
                      <li>
                        A designer with a tuned sense of elegance, taste, and
                        quality.
                      </li>
                      <li>A sparring partner, companion or coach.</li>
                      <li>
                        Someone who cares more about people than technology.
                      </li>
                      <li>Someone who makes not knowing into a strength.</li>
                      <li>Someone who doesn’t give up until we’re done.</li>
                    </Text>
                  </BulletList>
                </SplitTextWrapper>
                <SplitTextWrapper>
                  <Text heading5 medium as="h3">
                    You won't get
                  </Text>
                  <BulletList>
                    <Text body1 as="p">
                      <li>
                        The answers you want, instead of what you need to hear.
                      </li>
                      <li>
                        Someone who follows orders. I won’t do anything without
                        first knowing why, and without considering if it’s worth
                        doing.
                      </li>
                      <li>
                        Fake, buzzwordy, design speak. I say what I mean,
                        without being an ass about it.
                      </li>
                      <li>Poorly designed products and services.</li>
                    </Text>
                  </BulletList>
                </SplitTextWrapper>
              </SectionContent>
            </SectionContainer>
            <SectionContainer>
              <SectionHeader>
                <Text heading4 as="p">
                  That's it
                </Text>
                <Text body1 as="p">
                  Well done. You made it to the end.
                  <br></br>
                  <br></br>
                  Hopefully you got a picture of who I am. I think I went a bit
                  heavy in there. Oh well, that happens sometimes.
                  <br></br>
                  <br></br>
                  Keep in mind that I come from a position of privelige compared
                  to many. Growing up as a white man in Norway, there's a lot of
                  stuff I take for granted. Like, for instance, being able to
                  change education-paths without becoming financially bankrupt.
                  I realize that this is a luxury in the world we live in. Even
                  though, in my mind it should just be the norm.
                  <br></br>
                  <br></br>Regardless of that, I hope it didn’t diminish the
                  impact of the content too much, and that you found reading
                  about me a little interesting, if nothing else.
                  <br></br>
                  <br></br>
                  Thank you again for checking out my site.
                  <br></br>
                  <br></br>
                  I'm looking forward to hearing from you.
                </Text>
              </SectionHeader>
            </SectionContainer>
          </ContentContainer>
        </AnimationFadeWrapper>
      </Layout>
    </>
  );
};

export default AboutPage;

export const Head = () => (
  <Seo
    title={'About Jonathan Esbjug'}
    description={
      'I’m Jonathan. A 30-something-old designer and consultant based in Oslo, Norway. Nice to meet you.'
    }
    image={'./static/DefaultImage.png'}
    slug={'/about/'}
  />
);

export const AboutQuery = graphql`
  query {
    aboutImage: file(relativePath: { eq: "images/AboutImage.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(webpOptions: { quality: 40 })
      }
    }
  }
`;
